/* eslint-disable no-undef */
import React from "react"
import StructureCobra from "@components/pageCasosdeExito/template/templatePageCobra"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: Grupo Cobra [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/grupo-cobra/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre cómo el Grupo Cobra aumentó su contactabilidad en más del 20% gracias a la implementación de la API de WhatsApp Business con Beex."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: Grupo Cobra" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/grupo-cobra/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/CasosExito/og-cobra.jpg`}
      />
      <meta
        property="og:description"
        content="Descubre cómo el Grupo Cobra aumentó su contactabilidad en más del 20% gracias a la implementación de la API de WhatsApp Business con Beex."
      />
    </Helmet>
    <StructureCobra location={location} />
  </div>
)

export default IndexPage
